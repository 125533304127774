import {getEventDelay} from './get-event-delay';
import {throttle} from './throttle';
import {isInViewport} from './is-In-viewport';
import {speedNumber} from './speed-number';

export function addAnimations() {
  let $win = $(window);
  let itemsGlobal = [];
  let additionalAnimations = [
    {
      selector: '.counter',
      fn: speedNumber().animate
    }
  ];

  function addItems($items) {
    let items = $items || $('.has-animation');

    items.each(function () {
      let $item = $(this);
      let obj = {
        $item: $item,
        isAnimated: false
      };

      additionalAnimations.forEach(function (additionalItem) {
        if ($item.is(additionalItem.selector)) {
          obj.fn = function () {
            additionalItem.fn($item);
          };
        }
      });

      itemsGlobal.push(obj);
    });
  }

  function run() {
    if (itemsGlobal.length) {
      $.each(itemsGlobal, function (index, item) {
        let $item = item.$item;

        if (isInViewport($item, -13)) {
          item.isAnimated = true;
          $item.addClass('animate');

          if(item.fn !== undefined){
            item.fn();
          }
        }
      });

      itemsGlobal = itemsGlobal.filter(function (item) {
        return !item.isAnimated;
      });
    }
  }

  $win.on('load', function () {
    addItems();
    run();

    ['scroll', 'resize', 'orientationchange'].map(function (item) {
      let eventDelay = getEventDelay(item);
      $win.on(item, throttle(run, eventDelay));
    });
  });
}

import {isPrefersReducedMotion} from './a11y';

export function speedNumber() {
  let instance;
  let counterClassName = 'counter';
  let counterClassNameInit = counterClassName + '-init';
  let isAnimationsOn = !isPrefersReducedMotion();

  function createInstance() {
    let itemsGlobal = [];

    function addItems($itemsCounter) {
      if (!isAnimationsOn) {
        return;
      }
      
      let $items = $itemsCounter || $(`.${counterClassName}:not('[data-animate="false"]')`);

      $items.each(function () {
        let $item = $(this);

        if (!$item.hasClass(counterClassNameInit)) {
          $item.addClass(counterClassNameInit);
          let stat = $item[0];

          // pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
          const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/;
          const time = $item.data('delay') * 1;
          let result = [...patt.exec(stat.textContent)];
          let fresh = true;
          let ticks;

          // Remove first full match from result array (we dont need the full match, just the individual match groups).
          result.shift();
          // Remove undefined values from result array where they didnt have a match in one of the optional regex groups
          result = result.filter(res => res != null);

          while (stat.firstChild) {
            stat.removeChild(stat.firstChild);
          }

          for (let res of result) {
            if (isNaN(res)) {
              stat.insertAdjacentHTML('beforeend', `<span>${res}</span>`);
            } else {
              for (let i = 0; i < res.length; i++) {
                stat.insertAdjacentHTML(
                  'beforeend',
                  `<span data-value="${res[i]}">
          <span>&ndash;</span>
          ${Array(parseInt(res[i]) + 1)
    .join(0)
    .split(0)
    .map(
      (x, j) => `
              <span>${j}</span>
            `
    )
    .join('')}
        </span>`
                );
              }
            }
          }

          ticks = [...stat.querySelectorAll('span[data-value]')];
          let activate = () => {
            setTimeout(() => {
              fresh = false;
            }, time);


            setTimeout(() => {
              for (let tick of ticks) {
                let dist = parseInt(tick.getAttribute('data-value')) + 1;
                tick.style.transform = `translateY(-${dist * 100}%)`;
              }
            }, fresh ? time : 0);

          };

          itemsGlobal.push({
            $item: $item,
            run: activate
          });
        }
      });
    }

    addItems(); //init

    return {
      addItems: addItems,
      animate: function ($counter) {
        if (isAnimationsOn && $counter.length) {
          itemsGlobal.forEach(function (item) {
            if (item.$item.is($counter)) {
              item.run();
            }
          });
        }
      }
    };
  }

  if (speedNumber.instance === undefined) {
    instance = createInstance();
    speedNumber.instance = instance;
  } else {
    instance = speedNumber.instance;
  }

  return instance;
}

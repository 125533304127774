// @ts-check
/**
 * Helper function to send info to the GTM Datalayer
 * @param {Object} dataLayerInfo Key value pairs to send into the GTM Datalayer
 */
export function sendToDataLayer(dataLayerInfo) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerInfo);
}

let block_data_map = new Map();

export function ga4EventTracking() {
  setupBlockListeners();
}

function setupBlockListeners() {

  const sections = document.querySelectorAll('section');
  const observerOptions = { rootMargin: '-200px' };
  const delay = 2;

  sections.forEach(section => {
    let default_event_data = {
      event: 'block_interaction'  
    };

    default_event_data = {...default_event_data, ...getBlockAttributes(section)};
    
    block_data_map.set(addBlockTrackingID(section), default_event_data);

    /**
     * @type {string | number | NodeJS.Timeout}
     */
    let observerTimeout = null;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observerTimeout = setTimeout(() => {
            sendToDataLayer({...default_event_data, ...{'blockStatus': 'displayed', 'blockInteractionType': 'n/a', 'blockInteractionValue' : 'n/a'}});
            observer.unobserve(entry.target);
          }, delay * 1000);
        }
        else {
          clearTimeout(observerTimeout);  
        }
      });
    }, observerOptions);

    observer.observe(section);

    setupClickListeners(section, default_event_data);

    sendToDataLayer({...default_event_data, ...{'blockStatus': 'loaded', 'blockInteractionType': 'n/a', 'blockInteractionValue' : 'n/a'}});
  });

}

/**
 * Adds a unique tracking id data attribute for use later by block interactions outside of this script (i.e. carousels and accordions)
 * @param {HTMLElement} section 
 * @returns {String} blockTracking ID that was created
 */
function addBlockTrackingID(section) {

  const blockName = section?.dataset.blockName?.replace(/\s+/g, '-').toLowerCase();
  const blockTrackingID = `${blockName}-${Math.random().toString(36).slice(2)}`;
  section.dataset.blockTrackingId = blockTrackingID;

  return blockTrackingID;

}

/**
 * 
 * @param {HTMLElement} section 
 * @param {Object} default_event_data 
 */
function setupClickListeners(section, default_event_data) {
  section.querySelectorAll('a').forEach(link => {
    link.addEventListener('click', e => {
      sendToDataLayer({...default_event_data, ...{'blockStatus': 'interacted', 'blockInteractionType': 'clicked', 'blockInteractionValue' : e.target?.href }});
    });
  });
}

/**
 * 
 * @param {HTMLElement} block 
 * @returns 
 */
function getBlockAttributes(block) {
  const data_values = ['blockName','blockVariation'];
  const block_data_attributes = new Object();

  const block_dataset = block.dataset;
  
  for( let key in block_dataset) {
    if(data_values.includes(key)) {
      block_data_attributes[key] = block_dataset[key];
    }
  }

  if (block.querySelector('h1')) {
    block_data_attributes['blockHeading'] = block.querySelector('h1').textContent;
  }
  else if (block.querySelector('h2')) {
    block_data_attributes['blockHeading'] = block.querySelector('h2').textContent;
  }

  if (block.querySelectorAll('a')) {
    block_data_attributes['blockNumLinks'] = block.querySelectorAll('a').length;
  }
  
  return block_data_attributes;
}

/**
 * Allows external interactions like accordions and carousels to send actions back for tracking
 * @param {HTMLElement} section
 * @param {String} action Action taken like toggled, slide
 * @param {String} action_value Value of the action taken (accordion number, slide number)
 */
export function trackExternalBlockInteraction(section, action, action_value) {
  sendToDataLayer({...block_data_map.get(section.dataset.blockTrackingId),...{'blockStatus': 'interacted', 'blockInteractionType': action, 'blockInteractionValue': action_value}});
}

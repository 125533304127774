import { onLCP, onFID, onCLS, onINP } from 'web-vitals';
import { sendToDataLayer } from './ga4-event-tracking';


(() => {
  // console.log('hello from web-vitals.js');
  /*
  Generate a selector to help identify the entry target node
  First, add a function to the script block that generates a string representation of the node and its place in the DOM, in a format similar to a CSS selector. The output of this function helps identify which elements in the page are responsible for your CWV values.
  */
  function getSelector(node, maxLen = 100) {
    let sel = '';
    try {
      while (node && node.nodeType !== 9) {
        const part = node.id ? '#' + node.id : node.nodeName.toLowerCase() + (
          (node.className && node.className.length) ?
            '.' + Array.from(node.classList.values()).join('.') : '');
        if (sel.length + part.length > maxLen - 1) return sel || part;
        sel = sel ? part + '>' + sel : part;
        if (node.id) break;
        node = node.parentNode;
      }
    } catch (err) {
    // Do nothing...
    }
    return sel;
  }

  /*
  Retrieve LayoutShift information
  To log every layout shift that occurs is likely to generate an excessive amount of data. The functions below focus only on the largest LayoutShift entry, and the largest LayoutShiftSource within it. This lets you focus your optimizations on the most significant causes of layout shifts on your site. As you identify the causes of layout shifts and find ways to minimize them, the layout shift source you see in your reports changes to show the new worst offender.
  */
  function getLargestLayoutShiftEntry(entries) {
    return entries.reduce((a, b) => a && a.value > b.value ? a : b);
  }

  function getLargestLayoutShiftSource(sources) {
    return sources.reduce((a, b) => {
      return a.node && a.previousRect.width * a.previousRect.height >
          b.previousRect.width * b.previousRect.height ? a : b;
    });
  }

  /*
  Determine if FID happened before or after DOMContentLoaded
  The DOMContentLoaded event takes place after the page's HTML has completely loaded and parsed, which includes waiting for any synchronous, deferred, or module scripts (including all statically imported modules) to load. This function returns true if the first user input happened before DOMContentLoaded, or false if it happened after.
  */
  function wasFIDBeforeDCL(fidEntry) {
    const navEntry = performance.getEntriesByType('navigation')[0];
    return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
  }


  /*
  Identify the FID target element
  Another potentially useful debug signal is the element that was interacted with. While the interaction with the element itself does not contribute to FID (remember FID is just the delay portion of the total event latency), knowing which elements your users are interacting with may be useful to determine how best to improve FID.

  To get the element associated with the first input event, reference the first-input entry's target property:
  */
  // function getFIDDebugTarget(entries) {
  //   return entries[0].target;
  // }

  /*
  Identify the FID input event type
  It may also be useful to capture which type of event triggered the FID measurement to identify how users are interacting with your pages.
  */
  // function getFIDEventType(entries) {
  //   return entries[0].name;
  // }


  /*
  Structure the debug information for each CWV
  The last step before sending this code to Google Analytics is to structure the information from the entries, including the information returned by the above functions.
  */
  function getDebugInfo(name, entries = []) {
    // In some cases there won't be any entries (e.g. if CLS is 0,
    // or for LCP after a bfcache restore), so we have to check first.
    if (entries.length) {
      if (name === 'LCP') {
        const lastEntry = entries[entries.length - 1];
        return {
          debug_target: getSelector(lastEntry.element),
          event_time: lastEntry.startTime,
        };
      } else if (name === 'FID') {
        const firstEntry = entries[0];
        return {
          debug_target: getSelector(firstEntry.target),
          debug_event: firstEntry.name,
          debug_timing: wasFIDBeforeDCL(firstEntry) ? 'pre_dcl' : 'post_dcl',
          event_time: firstEntry.startTime,
        };
      } else if (name === 'CLS') {
        const largestEntry = getLargestLayoutShiftEntry(entries);
        if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
          const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
          if (largestSource) {
            return {
              debug_target: getSelector(largestSource.node),
              event_time: largestEntry.startTime,
            };
          }
        }
      } else if (name === 'INP') {
        const targetEntry = entries[0];
        return {
          debug_target: getSelector(targetEntry.target),
          event_time: targetEntry.startTime
        };
      }
    }
    // Return default/empty params in case there are no entries.
    return {
      debug_target: '(not set)',
    };
  }

  /*
  Send the data to Google Analytics
  Finally, create a function that takes parameters from the web-vitals event and passes them to Google Analytics.
  */
  function sendToGoogleAnalytics({ name, delta, value, id, entries }) {
    sendToDataLayer({
      'event': 'coreWebVitals',
      'webVitalsMeasurement': {
        'name': name,
        'id': id,
        'value': value,
        'delta': delta,
        ...getDebugInfo(name, entries)
      }
    });
  }
  
  onCLS(sendToGoogleAnalytics);
  onFID(sendToGoogleAnalytics); // can eventually be removed
  onINP(sendToGoogleAnalytics, { reportAllChanges: true });
  onLCP(sendToGoogleAnalytics);

  // reference guide
  // https://codelabs.developers.google.com/codelabs/web-vitals-google-analytics-bigquery
})();

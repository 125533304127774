(() => {
  const navEl = document.querySelector('header.header');

  if ( ! navEl ) {
    return;
  }

  const navHeight = navEl.offsetHeight;
  
  // make nav offset dynamic
  document.documentElement.style.setProperty('--scroll-padding', `${navHeight}px`);

})();

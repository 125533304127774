import {sendBrowserPreferrencesToGTM, toggleContrast} from './a11y';
import {geoTarget} from './geo-target';
import {setUTMCampaignCookies} from './utm-cookies';
import {binder} from '../libs/binder';
import {constants} from './module';
import {header} from './header';
// import {footer} from '../modules/footer';
import {heroSection} from './hero-section';
import {goToAnchor, scrollToHash} from './go-to-anchor';
import { wpTable } from './wp-table';
import { addAnimations } from './add-animations';
import { demoSection } from './demo-section';
import { ga4EventTracking } from './ga4-event-tracking';
import { sendToDataLayer } from '../libs/datalayer';


export function onPageLoad() {
  const { ajaxvars = {} } = window;
  const { user_country = '' } = ajaxvars;

  ga4EventTracking();
  toggleContrast();
  geoTarget();
  sendBrowserPreferrencesToGTM();
  setUTMCampaignCookies();
  demoSection();

  binder({
    bounds: {
      'html': [constants, scrollToHash],
      '.header': header,
      // '.footer': footer,
      '.has-animation': addAnimations,
      '.hero-section': heroSection,
      '[data-goto-anchor], .anchors': goToAnchor,
      '.wp-block-table': wpTable
    },
    runTests: false
  });

  sendToDataLayer({
    event: 'geotracking',
    user_country
  });
}

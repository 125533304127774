import {scrollTo} from './go-to-anchor';

export function scrollEventToggle() {
  let topWindowScroll;
  let $body = $('body');

  function disableScroll() {
    topWindowScroll = window.pageYOffset || document.documentElement.scrollTop;
    $body.addClass('disabled');
  }

  function enableScroll(isScrollTo) {
    $body.removeClass('disabled');

    if (isScrollTo) {
      scrollTo(topWindowScroll);
    }
  }

  return {
    disable: disableScroll,
    enable: enableScroll
  };
}
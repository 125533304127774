import Cookies from '../libs/cookies';
import { sendToDataLayer } from './ga4-event-tracking';

export function isPrefersReducedMotion() {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

  return !mediaQuery || mediaQuery.matches;
}

export function isPrefersContrastMore() {
  const mediaQuery = window.matchMedia('(prefers-contrast: more)');

  return !mediaQuery || mediaQuery.matches;
}

export function isPrefersDarkMode() {
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  return !mediaQuery || mediaQuery.matches;
}

export function sendBrowserPreferrencesToGTM() {
  const prefers = [];
  if (isPrefersReducedMotion()) {
    prefers.push('prefers-reduced-motion');
  }

  const contrastToggle = document.querySelector('#toggle-contrast');
  if (contrastToggle?.checked) {
    prefers.push('checked-high-contrast');
  }

  if (isPrefersContrastMore()) {
    prefers.push('prefers-high-contrast');
  }

  if (isPrefersDarkMode()) {
    prefers.push('prefers-dark-mode');
  }

  if (prefers.length) {
    sendToDataLayer({
      event: 'browser_preferences',
      browser_preferences: prefers.join('|')
    });
  }

}

export function toggleContrast() {
  const contrastStoreName = 'contrastIsHigh';
  const contrastToggle = document.querySelector('#toggle-contrast');
  let storedContrastValue = false;

  try {
    storedContrastValue = localStorage.getItem(contrastStoreName) === 'true' || Boolean(Cookies.get(contrastStoreName));
  } catch (_err) {
    // do nothing
  }

  const status = Boolean(storedContrastValue || isPrefersContrastMore());
  
  const toggleHIClass = function (isAdd) {
    document.querySelector('body').classList[(isAdd ? 'add' : 'remove')]('high-contrast');

    // toggle contrast on web component forms
    document.querySelectorAll('trynow-form').forEach(el => {
      const status = el.getAttribute('high-contrast');
      el.setAttribute('high-contrast', status === '0' ? '1' : '0');
    });
  };

  toggleHIClass(status);

  if (status) {
    contrastToggle?.setAttribute('checked', status.toString());
  }
  
  contrastToggle?.addEventListener('change', e => {
    const element = e.target;
    const isChecked = element.checked;
    toggleHIClass(isChecked);
    localStorage.setItem(contrastStoreName, isChecked.toString());
  });
}

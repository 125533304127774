import '../../css/blocks/demo-section.scss';
import { trackExternalBlockInteraction } from './ga4-event-tracking';

function activateStaticScreen(image) {
  const replacementTitle = image.getAttribute('data-title-swap');
  let direction = null;
  image.setAttribute('data-title-swap', image.getAttribute('title'));
  image.setAttribute('title', replacementTitle);
  
  if (image.classList.contains('move-front')) {
    image.classList.add('move-back');
    image.classList.remove('move-front');
    image.setAttribute('aria-pressed', 'false');
    direction = 'move back';
  }
  else {
    image.classList.add('move-front');
    image.classList.remove('move-back');
    image.setAttribute('aria-pressed', 'true');
    direction = 'move forward';
  }

  trackExternalBlockInteraction(image.closest('section'), direction, image.dataset.imageSide);
}

export function demoSection() {
  document.querySelectorAll('.demo-section .static-image').forEach(image => {
    image.addEventListener('click', () => {
      activateStaticScreen(image);
    });
    image.addEventListener('keydown', event => {
      if (event instanceof KeyboardEvent && event.key !== 'Enter' && event.key !== ' ') {
        return;
      }

      activateStaticScreen(image);
    });

    image.addEventListener('animationend', () => {
      if (image.classList.contains('move-back')) {
        image.classList.remove('move-back');
      }
    });
  });
}

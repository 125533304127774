import {getEventDelay} from './get-event-delay';
import {throttle} from './throttle';
import Cookies from '../libs/cookies';


function replaceStaticHerosWithAnimated() {
  const animatedHeroEls = document.querySelectorAll( '[data-animated-src]' );

  if ( ! animatedHeroEls.length ) {
    return;
  }

  setTimeout(() => {
    animatedHeroEls.forEach(el => {
      el.style.backgroundImage = `url('${el.getAttribute('data-animated-src')}')`;
    });
  }, 2000);
}

export function heroSection() {
  let win = window;
  let itemsGlobal = [];
  let prefix = '.hero-section';
  // let decorBlockBreakpoints = {
  //   0: 120,
  //   1200: 192,
  //   1440: 228,
  //   1800: 298
  // };

  document.addEventListener('DOMContentLoaded', () => {

    let $win = $(win);

    $win.on('load', function () {
      addItems();
      run();

      ['resize', 'orientationchange'].map(function (item) {
        let eventDelay = getEventDelay(item);
        $win.on(item, throttle(run, eventDelay));
      });
    });

    /*
    pages affected:
    - /contact-sales/
    - /demo-request/
    */
    if (document.querySelector('.hero-section .hero-swiper')) {
      import('../libs/swiper_v.5.4.1').then(() => {
          
        // Saving the status of the video in Cookies, so the settings stay the same across the site.
        function savePlayPauseHeroSetting(video) {
          if (video.paused === true) {
            Cookies.set('hero-video-paused', true);
          } else {
            Cookies.set('hero-video-paused', false);
          }
        }

        function playPauseHeroVideo(saveSetting, e) {
          let targetParent = e.parentNode;
          let animationID = targetParent.dataset.videoid;
          let animationItem = document.getElementById(animationID);
          const pauseBtnEl = targetParent.querySelector('.btn-pause');
          const playBtnEl = targetParent.querySelector('.btn-play');

          if (animationItem.paused === true) {
            animationItem.play();
          } else {
            animationItem.pause();
          }
          
          pauseBtnEl.classList.toggle('hidden');
          playBtnEl.classList.toggle('hidden');

          if (saveSetting === true) {
            savePlayPauseHeroSetting(animationItem);
          }
        }
          
        // The animation buttons on mp4 animations
        function animationBtnClickEvent(animationBtn) {
          animationBtn.addEventListener('click', function (e) {
            playPauseHeroVideo(true, e.target);
                  
            e.preventDefault();
          }, false);
        }
          
        const animationControls = document.querySelectorAll('a.animation-controls');
    
        animationControls.forEach(animationBtnClickEvent);
          
        // If the user has "prefers-reduced-motion" enabled, we can pause the video at the start
        let motionQuery = matchMedia('(prefers-reduced-motion)');
        let heroVideoPaused = Cookies.get('hero-video-paused');
    
        if (motionQuery.matches || heroVideoPaused === 'true') { //reduced behaviour;
          animationControls.forEach((thisControl) => {
            let animatedVideo = document.getElementById(thisControl.dataset.videoid);
            animatedVideo.onplay = function () {
              playPauseHeroVideo(false, thisControl.querySelector('.btn-pause'));
              animatedVideo.onplay = null;
            };
          });
        }
    
        $(document).ready(function () {
    
          let prefersReducedMotion;
    
          try {
            prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
          } catch (err) {
            prefersReducedMotion = null;
          }
    
          const effect = prefersReducedMotion ? 'fade' : 'slide';
          const speed = prefersReducedMotion ? 1000 : 300;
    
          // init slider
          const swiper = new Swiper('.hero-swiper', {
            loop: true,
            slidesPerView: 1,
            effect,
            speed,
            spaceBetween: 0,
            watchSlidesVisibility: false,
            watchOverflow: false,
            autoplay: {
              delay: 3000
            }
          });
    
          function swiperCallback(swiperInstance) {
            if (!swiperInstance || !swiperInstance.el) return;
    
            const swiperPauseBtn = swiperInstance.$el.find('.btn-pause');
            const swiperPlayBtn = swiperInstance.$el.find('.btn-play');
    
            if (!(swiperPlayBtn.length || swiperPauseBtn.length)) return;
    
            const targetClass = 'hidden';
    
            swiperPauseBtn[0].addEventListener('click', function () {
              const { running, stop } = swiperInstance.autoplay;
              if (!running) return;
              this.classList.toggle(targetClass);
              swiperPlayBtn[0].classList.toggle(targetClass);
              swiperPlayBtn[0].focus();
              stop();
            });
    
            swiperPlayBtn[0].addEventListener('click', function () {
              const { running, start } = swiperInstance.autoplay;
              if (running) return;
              this.classList.toggle(targetClass);
              swiperPauseBtn[0].classList.toggle(targetClass);
              swiperPauseBtn[0].focus();
              start();
            });

            swiperInstance.on('slideChangeTransitionEnd', function () {
              const { slides } = this;

              Array.from(slides).forEach(slideEl => {
                if (slideEl.classList.contains('swiper-slide-active')) {
                  slideEl.setAttribute('tabindex', '0');
                } else {
                  slideEl.setAttribute('tabindex', '-1');
                }
              });
            });
          }
    
          if (Array.isArray(swiper)) {
            swiper.forEach(swiperCallback);
          } else {
            swiperCallback(swiper);
          }
        });
      });
    }

    replaceStaticHerosWithAnimated();

    function playPauseBackgroundVideo(video) {
      if (video.paused) 
        video.play(); 
      else 
        video.pause();
    }

    if (document.querySelector('.video-container video') && document.querySelector('.video-container .animation-controls')) {
      let motionQuery = matchMedia('(prefers-reduced-motion)');
      const video = document.querySelector('.video-container video');
      const pauseBtn = document.querySelector('.animation-controls .btn-pause');
      const playBtn = document.querySelector('.animation-controls .btn-play');
      
      if (motionQuery.matches) {
        video.currentTime = 5;
        video.pause();
        pauseBtn.classList.add('hidden');
        playBtn.classList.remove('hidden');
      }

      pauseBtn.addEventListener('click', () => {

        video.pause();
        pauseBtn.classList.add('hidden');
        playBtn.classList.remove('hidden');
      });

      playBtn.addEventListener('click', () => {
        video.play();
        playBtn.classList.add('hidden');
        pauseBtn.classList.remove('hidden');
      });
    
    }


    function addItems($items) {
      let items = $items || $(prefix);

      items.each(function () {
        let $item = $(this);
        let $wrapColumns = $item.next('.wrap-columns-layout');

        itemsGlobal.push({
          $left: $item.find(prefix + '-left'),
          isLargeType: $item.hasClass('height-large'),
          $wrapColumns: $wrapColumns,
          $formContainer: $wrapColumns.find('.form-container')
        });
      });
    }

    function run() {
      if ($('.hero-section.height-small').length || $('.single-post').length || $('.single-career-resources').length || $('.single-stories').length || $('.single-podcasts').length || ($('.single-resources').length && !$('.resources-template-single-gated').length)) { 
        // Don't run this on single post pages
        return;
      }

      if (!itemsGlobal.length) {
        return;
      }
      
      const maxHeight = 1000;
      let windowWidth = win.innerWidth;
      let windowHeight = win.innerHeight;
      let isPortrait = windowHeight > windowWidth;
      let isLargeBreakpoint = windowWidth >= 1024;

      $.each(itemsGlobal, function (index, item) {
        let minHeight = 0;
        const target = '$left' in item && Array.isArray(item.$left) && item.$left.length > 0 ? item.$left[0] : null;

        if (!$('.resources-template-single-gated').length && target && ! target.closest('.hero-section-video')) {
          
          if (isLargeBreakpoint) {
            let coefficientPortrait = isPortrait ? .45 : 1;
            let coefficientHeroLargeType = item.isLargeType ? 1 : .9;

            minHeight = windowHeight * coefficientPortrait * coefficientHeroLargeType;

            if (minHeight > maxHeight) {
              minHeight = maxHeight;
            }
          }

          item.$left.css('min-height', minHeight + 'px');
        }

        if (item.$formContainer.length) {
          requestAnimationFrame(function () {
            let indent = isLargeBreakpoint ? '-' + (item.$wrapColumns.offset().top - item.$left.offset().top) : 0;
            item.$formContainer.css('margin-top', indent + 'px');
          });
        }
      });
    }
  });
}

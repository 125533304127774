// import 'babel-polyfill';
import './modules/navigation';
import './modules/web-vitals';
import { onPageLoad } from './modules/on-page-load';
import moduleMap from './module-map';
import ModuleMapper from './class.module-mapper';
import '../css/style.scss';


window.$ = window.jQuery;

const mapper = new ModuleMapper(moduleMap);

onPageLoad();
mapper.init();

// video lazy loading experiment
window.onload = () => {
  const heroVideoEl = document.querySelector('#featuredVideo');
  const heroVideoSourceEl = heroVideoEl ? heroVideoEl.querySelector('source') : null;

  if (!heroVideoSourceEl) return;

  const videoUrl = heroVideoSourceEl.getAttribute('data-src');
  
  heroVideoSourceEl.setAttribute('src', videoUrl);
  heroVideoEl.load();
};

document.querySelectorAll('a[href^="#"]').forEach(el => {
  el.addEventListener('click', () => {
    mapper.globalLoadMethod = 'load';
  });
});

export function getEventDelay(eventName) {
  //Get duration event has delay
  let delay;

  switch (eventName) {
  case 'scroll':
    delay = 100;
    break;

  default:
    delay = 405;
  }

  return delay;
}
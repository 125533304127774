import Cookies from '../libs/cookies';
import { sendToDataLayer } from './ga4-event-tracking';
import '../../css/blocks/dialog.scss';

// declare global {
// interface Window { ajaxvars: any; } // eslint-disable-line @typescript-eslint/no-explicit-any
// }

export function geoTarget() {

  const { ajaxvars = {} } = window;
  
  const {
    ajaxurl = '',
    geo_target_nonce_token = '',
  } = ajaxvars;

  if ( ! sessionStorage.getItem('country') && ajaxurl && geo_target_nonce_token ) {
    fetch(ajaxurl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `action=geo_target&_ajax_nonce=${geo_target_nonce_token}`
    })
      .then( response => response.json() )
      .then ( data => {
        const {
          country: countryData = { iso_code: '' },
          region: regionData = { name: '' }
        } = data;

        const { iso_code: country = '' } = countryData;
        const { name: region = '' } = regionData;

        // const country = data.country.iso_code;
        // const region = data.region.name;

        sessionStorage.setItem('country', country);

        if (region !== null && region !== 'Undefined'){
          sessionStorage.setItem('region', region);
        }
      });
  }

  const regionalPopup = document.querySelector('dialog#regional-popup');
  
  if (!regionalPopup || typeof (sessionStorage.getItem('hideRegionalPopup')) === 'string' || 
      Cookies.get('preferred-country') === regionalPopup.dataset.preferredCountry ||
      document.querySelector('body.logged-in')) { // When logged in don't present the popup
    return;
  }
  
  let savedCountry = sessionStorage.getItem('preferred-country');
  
  if (typeof (Cookies.get('preferred-country')) === 'string' && Cookies.get('preferred-country') !== '') {
    savedCountry = Cookies.get('preferred-country');
  }
  const acceptButton = regionalPopup.querySelector('#accept-button');
  const popupCountry = acceptButton.dataset.preferredCountry;
  const acceptedUrl = acceptButton.href;
  if (savedCountry === popupCountry) {
    sendToDataLayer({
      event: 'regional_popup',
      promo_type: 'region popup',
      promo_status: 'interacted',
      interaction_type: 'redirected',
      promoted_page: acceptedUrl
    });
    window.location.href = acceptedUrl;
    return;
  }

  // regionalPopup?.showModal();

  if (typeof regionalPopup.showModal === 'function') {
    regionalPopup.showModal();
  }

  sendToDataLayer({
    event: 'regional_popup',
    promo_type: 'region popup',
    promo_status: 'displayed',
    promoted_page: acceptedUrl
  });

  document.querySelector('#regional-popup a.btn').addEventListener('click', (e) => {
    let cookieModifier = '';
    const langCheckbox = document.querySelector('#toggle-preferred-language');
    const button = e.target;
    if (langCheckbox.checked) {
      Cookies.set('preferred-country', button.dataset.preferredCountry, { expires: 7 });
      cookieModifier = '-preferred';
    }
    
    sendToDataLayer({
      event: 'regional_popup',
      promo_type: 'region popup',
      promo_status: 'interacted',
      interaction_type: `accepted${cookieModifier}`,
      promoted_page: acceptedUrl
    });

    // if (window.checkCookieGroupsActive('functional') === true) {
    //   Cookies.set('country-preferred', mappedURL, { expires: 7 }); // Decided to use cookies here since they can expire, local storage cannot.
    // } else {
    //   sessionStorage.setItem('country-preferred', mappedURL); // Decided to use cookies here since they can expire, local storage cannot.
    // }
  });

  document.querySelectorAll('dialog#regional-popup button').forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const button = e.target;
      const popup = document.querySelector('dialog#regional-popup');
      popup.close();
      closePopup(button.value, popup.dataset.preferredCountry);
    });
  });

  function closePopup(interactionType, preferredCountry) {
    let cookieModifier = '';
    const languageCheckbox = document.querySelector('#toggle-preferred-language');
    sessionStorage.setItem('hideRegionalPopup', 'true');
    
    if (languageCheckbox.checked && interactionType === 'interacted') {
      Cookies.set('preferred-country', preferredCountry, { expires: 7 });
      cookieModifier = '-preferred';
    }
    
    sendToDataLayer({
      event: 'regional_popup',
      promo_type: 'region popup',
      promo_status: interactionType,
      interaction_type: `declined${cookieModifier}`,
      promoted_page: acceptedUrl
    });
  }
}

let anchorAttrName = 'data-goto-anchor';

export function scrollTo(position, callback) {
  let duration = arguments.length <= 2 || arguments[2] === undefined ? 400 : arguments[2];

  $('body, html').animate({scrollTop: position}, duration, function () {
    if (callback) {
      callback();
    }
  });
}

export function scrollToRecursion(target) {
  //var duration = arguments.length <= 1 || arguments[1] === undefined ? 200 : arguments[1];

  let getPosition = function getPosition() {
    let header = $('.header-height-info');
    let correctionPosition = 0;

    if (header.length) {
      correctionPosition = parseInt(header.outerHeight());
    }

    return parseInt(target.offset().top) - correctionPosition;
  };
  let position = getPosition();

  scrollTo(position, function () {
    let newPosition = getPosition();
    /*an extra scroll cycle (starting an additional iteration recursion) is made specifically to make allowances for lazy loading blocks*/
    if (position != newPosition) {
      scrollToRecursion(target);
    }
  });
}

export function goToAnchor() {
  $(document).ready(function () {
    $('body').on('click', '[' + anchorAttrName + ']', function (e) {
      e.preventDefault();

      let _this = $(this);

      scrollToRecursion($('[data-anchor=' + _this.attr(anchorAttrName) + ']'), 400);
    }).on('click', '.anchors a', function (e) {
      e.preventDefault();

      let _this = $(this);

      scrollToRecursion($(''+_this.attr('href')+''), 400);
    });
  });
}

function getUrlHash() {
  let linkArray = window.location.href.split('#');

  return (linkArray[1] == undefined) ? null : linkArray[1];
}

export function scrollToHash() {
  let urlHash = getUrlHash();

  if (urlHash != null) {
    let target = $(document.getElementById(urlHash));

    if (target.length) {
      scrollToRecursion(target);
    }
  }
}
export function isInViewport(obj, coefficientOffsetVH) {
  //0 - 300%
  let $win = $(window);
  let offsetTop = parseInt(obj.offset().top);
  let winScrollTop = $win.scrollTop();
  let bottomPosition = parseInt(offsetTop + obj.outerHeight());
  let winHeight = $win.height();
  let diffHeight = coefficientOffsetVH == 0 ? -5 : winHeight / 100 * coefficientOffsetVH;

  return !(bottomPosition < winScrollTop || offsetTop - diffHeight > winScrollTop + winHeight);
}
export function throttle(func, delay) {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;

    if (timer == null) {
      timer = setTimeout(function () {
        func.apply(context, args);
        timer = null;
      }, delay);
    }
  };
}
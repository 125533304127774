// @ts-check
import Cookies from '../libs/cookies';

/**
 * @typedef utmObj
 * @property {string} utmObj.utm_campaign
 * @property {string} utmObj.utm_source
 * @property {string} utmObj.utm_medium
 *  
 */

/**
 * 
 * @param {URL} url url of the page
 * @param {string} param name of the query param you want to get
 * @returns {string} value of the query param
 */
export function getUrlParam(url, param){
  return url.searchParams.get(param);
}

/**
 * Sets a cookie based on the values provided
 * @param {string} name Name of the cookie
 * @param {string} value Value to store in the cookie
 * @param {string} host Host value of the cookie
 * @param {number} expires Number of days to expire the cookie in
 * @param {boolean} reset Set to true if you want to overwrite an existing cookie. Defaults to false.
 * @returns
 */
function setCookie(name, value, host, expires, reset = false) {
    
  // Allows for overwrites of a null value to delete the current cookie
  if (!value && reset) {
    Cookies.set(name, value, {
      path: '/',
      domain: host,
      expires: 0,
    });
  }
    
  if (!value) {
    return;
  }

  Cookies.set(name, value, {
    path: '/',
    domain: host,
    expires: expires,
  });
}

/**
 * @param {string} referrer String URL of the refferer. Set for testing. Defaults to document.referrer
 * @returns {utmObj} An object that contains the key value pairs of utms campaign, source and medium
 */
export function getUTMs(referrer = document.referrer) {

  const url = new URL(window.location.href);

  let utm_campaign = getUrlParam(url, 'utm_campaign') || 'Not-Provided';
  let utm_source = getUrlParam(url, 'utm_source');
  let utm_medium = getUrlParam(url, 'utm_medium');

  if (!utm_source || !utm_medium) {

    if (referrer === '' || !referrer) {
      utm_medium = 'direct';
      utm_source = 'd2l.com';
    }
    else {
      // remove www from referrering domains
      const cleanReferral = referrer.replace('www.','');
      
      const searchRegex = /(google|bing|yahoo|duckduckgo|baidu|ask|aol|wolframalpha|yandex|startpage)/gi;
      const socialRegex = /(facebook|youtube|whatsapp|instagram|wechat|tiktok|messenger|twitter|^t\.co|^x\.com|reddit|linkedin|^lnkd\.in|glassdoor|pinterest|snapchat|viber|discord|twitch|quora)/gi;
      
      const referrerURL = new URL(cleanReferral);

      const searchMatches = referrerURL?.hostname?.match(searchRegex);
      const socialMatches = referrerURL?.hostname?.match(socialRegex);
      
      if (searchMatches) {
        utm_medium = 'organic';
        utm_source = searchMatches[0];
      }
      else if (socialMatches) {
        utm_medium = 'social';
        utm_source = socialMatches[0];
      }
      else {
        utm_medium = 'referral';
        utm_source = referrerURL?.hostname || referrer;
      }
    }
    
  }

  return {'utm_campaign': utm_campaign, 'utm_source': utm_source, 'utm_medium': utm_medium};
}

function getGCLID() {
  const url = new URL(window.location.href);

  const gclid = getUrlParam(url, 'gclid');
  const gclsrcParam = getUrlParam(url, 'gclsrc');

  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

  return (!gclid || !isGclsrcValid) ? null : gclid;
}

export function setUTMCampaignCookies() {
  const LAST_TOUCH_EXPIRY = 14;
  const FIRST_TOUCH_EXPIRY = 90;
  const hostCheck = 'd2l.com';
  let hostName = (location.hostname.indexOf(hostCheck) === -1) ? location.hostname : hostCheck;
  // Save UTM value in session

  const {utm_campaign, utm_source, utm_medium} = getUTMs();

  // Set GCLID if available
  const gclid = getGCLID(); 


  if (gclid) {
    setCookie('GCLID', gclid, hostName, FIRST_TOUCH_EXPIRY, true);
  }

  if (!utm_campaign && !utm_source && !utm_medium) {
    return;
  }
  

  if (!Cookies.get('utm_campaign') && !Cookies.get('utm_source') && !Cookies.get('utm_medium')) {
    // If the cookie doesn't exist, set all 3 items
    setCookie('utm_campaign', utm_campaign, hostName, FIRST_TOUCH_EXPIRY);
        
    setCookie('utm_source', utm_source, hostName, FIRST_TOUCH_EXPIRY);

    setCookie('utm_medium', utm_medium, hostName, FIRST_TOUCH_EXPIRY);
  }

  // Update the last touch cookie. This needs to be up here, as for some reason when it's lower in the script mktoForm / mktoFields become undefined.
  // Last Parameter to set cookie is true allowing these to be overwriten with a null value
  setCookie('utm_campaign_lt', utm_campaign, hostName, LAST_TOUCH_EXPIRY, true);
  setCookie('utm_source_lt', utm_source, hostName, LAST_TOUCH_EXPIRY, true);
  setCookie('utm_medium_lt', utm_medium, hostName, LAST_TOUCH_EXPIRY, true);

};

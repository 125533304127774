import {scrollEventToggle} from './scroll-event-toggle';
import {getEventDelay} from './get-event-delay';
import {throttle} from './throttle';
import { sendToDataLayer } from './ga4-event-tracking';
import Cookies from '../libs/cookies';


export function header() {
  let win = window;
  let $win = $(win);
  let $body = $('body');
  let $header = $('#header');
  let $headerInner = $header.find('.header-inner');
  let $headerTop = $headerInner.find('.header-top');
  let headerInnerIndentTop = 86;
  let activeClass = 'active';
  let openClass = 'open';
  let showMenuClass = 'show-menu';
  let openMenuName = 'open-menu';
  let expandedAttr = 'aria-expanded';
  let selectedAttr = 'aria-selected';
  let navListClassName = 'nav-list';
  let durationDefault = 400;
  let durationDesktop = 150;
  let orientationchangeEvent = 'orientationchange';
  let scrollYPosition = win.scrollY;
  let headerHasDownClass = 'header-has-down';
  let $headerInnerOverflow = $headerInner.css('overflow-y');
  
  let getIsDesktop = function () {
    return win.innerWidth >= 1200;
  };

  $header.find('nav.nav').append('<button class="menu-back" aria-labelledby="back-menu-text"><i class="icon-corner"></i><span id="back-menu-text"></span></button>');
  let $headerMenuBack = $header.find('.menu-back');

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  if (urlParams.has('nav_version')) {
    let navToTest = urlParams.get('nav_version');
    let expiry = new Date();
    expiry.setTime(expiry.getTime() + (30 * 24 * 60 * 60 * 1000));

    Cookies.set('nav_version', navToTest, { expires: 30 }); // expires value is in days
  }

  let navVersion = '';
  let nav_cookie = Cookies.get('nav_version');
  if (nav_cookie !== undefined) {
    navVersion = nav_cookie;
  }

  if (navVersion !== '') {
    const navTests = document.querySelectorAll('.nav-list [class*="nav-test-"]');

    navTests.forEach(el => {
      if (el.classList.contains('nav-test-' + navVersion)) {
        el.classList.add('nav-visibile');
      } else {
        el.classList.add('nav-invisibile');
      }
    });
  }

  function headerToggleClass(currentScrollYPosition) {
    if (currentScrollYPosition > 5) {
      let isDown = currentScrollYPosition > scrollYPosition;

      $header[`${isDown ? 'add' : 'remove'}Class`]('down');
      $body[`${isDown ? 'add' : 'remove'}Class`](headerHasDownClass);

    } else {
      $header.removeClass('down');
      $body.removeClass(headerHasDownClass);
    }

    scrollYPosition = currentScrollYPosition;
  }

  function getFeaturedNavArticleEvent(detail = {}) {
    return new CustomEvent(
      'featured_nav_article',
      { bubbles: true, detail: { ...detail } }
    );
  }
  
  /*products tabs*/
  function shortProductsLogics($btn, $item) {
    let isActive = $item.hasClass(activeClass);
    
    if (!isActive) {
      $item.addClass(activeClass);

      let index = $item.index();
      let $tabs = $item.closest('.dropdown-content').find('.tab');

      $btn.attr(selectedAttr, true);

      $item
        .siblings().removeClass(activeClass)
        .find('h3 > a').attr(selectedAttr, false);

      $headerMenuBack.find('span').text('');
      $headerMenuBack.find('span').text($item.find('a:not(.more)').text());
      $body.addClass('tab-menu-active');
      
      $tabs.each(function (tabIndex) {
        const isCurrentActiveTab = tabIndex === index;
        let action = 'remove';

        if (isCurrentActiveTab) {
          action = 'add';

          if ($(this)[0].querySelector('.has-featured')) {
            const promo_position = $btn[0].getAttribute('href');
            const event = getFeaturedNavArticleEvent({ index: tabIndex, promo_position });
            // promo status displayed
            $(this)[0].dispatchEvent(event);
          }
        }

        $(this)[`${action}Class`](activeClass);
      });
    }
  }

  if($('.language-block').length) {
    // remove CA-FR from the language drop down except for when a page is translated
    const frSelector = $('.language-block .wpml-ls-item-fr-ca');

    if (frSelector.length) {
      if ($(frSelector).find('a').attr('href').match(/\/fr-ca\/$/)) {
        frSelector.remove();
      }
    }
    
  }

  // add listener for dropdown submenu tabs
  const tabEls = document.querySelectorAll('.dropdown-right .tab');

  tabEls.forEach(el => {
    el.addEventListener('featured_nav_article', event => {
      // passing the tab index because the first tab will always initally be shown; prevents duplicate 
      const featuredTargetEl = event.target.querySelector('.has-featured');

      if ( ! featuredTargetEl || event.detail.index === 0 ) {
        return;
      }

      const { promo_position: promo_location } = event.detail;
      const featuredTargetAnchorEl = featuredTargetEl.querySelector('a');
      const link_url = featuredTargetAnchorEl.getAttribute('href');
      const { textContent: link_text } = featuredTargetAnchorEl;

      const dl_info = {
        event: 'internal_promo',
        navigation_type: 'nav_primary_3',
        promo_status: 'displayed',
        promo_location,
        promoted_page: link_url,
        promo_type: 'nav promo'
        // link_text
      };

      // console.log(dl_info);
      // debugger

      sendToDataLayer(dl_info);

    }, { once: true });
  });

  $('.short-products-list').on('click', 'h3 >a,.h3> a', function (e) {
    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }

    e.preventDefault();
    
    let $btn = $(this);
    
    shortProductsLogics($btn, $btn.closest('li'));
  
  }).on('mouseenter', '> li', function (e) {
    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }
    
    if (!e.target.classList.contains('more')) {
      let $item = $(this);
      shortProductsLogics($item.find('h3> a, .h3> a'), $item);
    }
  });

  /*back menu*/
  $headerMenuBack.on('click', function () {
    const isDesktop = getIsDesktop();

    if ($body.hasClass('tab-menu-active')) {
      $body.removeClass('tab-menu-active');
      $('.dropdown-right-inner.tab.active').removeClass('active');
      $('.short-products-list .active').removeClass('active');
      $headerMenuBack.find('span').text('');
      $headerMenuBack.find('span').text($('.menu-item-has-children.open .menu-item-button').text());
    } else {
      $('.menu-item-has-children.open').removeClass('open');
      $headerMenuBack.removeClass('show');
      
      if (!isDesktop) {
        $headerInner.css('overflow-y', $headerInnerOverflow);
      }

      $('.menu-item-link a').attr('aria-expanded', 'false');
    }
  });
  /*back menu end*/
  /*skip-content*/
  $('.skip-content a').click(function (e) {
    e.preventDefault();

    let mainContent = $(this.getAttribute('href'));
    let focusable = mainContent.find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

    if (focusable.length) {
      focusable[0].focus();
    } else {
      scrollTo(mainContent.offset().top);
    }
  });
  /*skip-content end*/

  /*fix D2L-589/Mobile | Navigation menu: part of the menu opens to up - it is not correct*/
  function fixInnerItemsPosition() {
    let headerTopIsFirst = $headerTop.index() === 0;

    if (getIsDesktop()) {
      if (!headerTopIsFirst) {
        $headerTop.prependTo($headerInner);
      }
    } else {
      if (headerTopIsFirst) {
        $headerTop.appendTo($headerInner);
      }
    }
  }

  /*fix D2L-589/Mobile | Navigation menu: part of the menu opens to up - it is not correct end*/

  /*
   *   This content is licensed according to the W3C Software License at
   *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
   *
   *   Supplemental JS for the disclosure menu keyboard behavior
   */

  class DisclosureNav {
    constructor(domNode) {
      let _this = this;

      this.rootNode = domNode;
      this.controlledNodes = [];
      this.openIndex = null;
      this.useArrowKeys = true;
      
      this.topLevelNodes = [
        ...this.rootNode.querySelectorAll(
          '.menu-item-button, .header-link'
        ),
      ];
      
      this.topItems = function () {
        let array = [];

        _this.topLevelNodes.forEach(function (item) {
          array.push(item.closest('li'));
        });

        return array;
      }();

      this.topLevelNodes.forEach(node => {

        node.addEventListener('featured_nav_article', event => {
          const { link_url, link_text, promo_position: promo_location } = event.detail;
          // promo status displayed
          const dl_info = {
            event: 'internal_promo',
            navigation_type: 'nav_primary_2',
            promo_status: 'displayed',
            promo_location,
            promoted_page: link_url,
            promo_type: 'nav promo'
            // link_text
          };
          // console.log(dl_info);
          // debugger

          sendToDataLayer(dl_info);

        }, { once: true });

        // handle button + menu
        if (node.tagName.toLowerCase() === 'a' && node.hasAttribute('aria-controls')) {
          const menu = node.closest('li').querySelectorAll('.dropdown')[0];
          const menuID = node.getAttribute('aria-controls');
          if (menu) {
            // save ref controlled menu
            this.controlledNodes[menuID] = menu;

            // collapse menus
            node.setAttribute(expandedAttr, 'false');
            this.toggleMenu(menu, false);

            // attach event listeners
            menu.addEventListener('keydown', this.onMenuKeyDown.bind(this));
            node.addEventListener('click', this.onButtonClick.bind(this));
            //node.addEventListener('mouseover', this.onButtonClick.bind(this));
            //menu.addEventListener('mouseout', this.onBlur.bind(this));
            node.addEventListener('keydown', this.onButtonKeyDown.bind(this));
          }
        }
        // handle links
        else {
          //this.controlledNodes.push(null);
          node.addEventListener('keydown', this.onLinkKeyDown.bind(this));
        }
      });
      //console.log(this.controlledNodes);

      //this.rootNode.addEventListener('focusout', this.onBlur.bind(this));
    }

    controlFocusByKey(keyboardEvent, nodeList, currentIndex) {
      switch (keyboardEvent.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let prevIndex = Math.max(0, currentIndex - 1);
          nodeList[prevIndex].focus();
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let nextIndex = Math.min(nodeList.length - 1, currentIndex + 1);
          nodeList[nextIndex].focus();
        }
        break;
      case 'Home':
        keyboardEvent.preventDefault();
        nodeList[0].focus();
        break;
      case 'End':
        keyboardEvent.preventDefault();
        nodeList[nodeList.length - 1].focus();
        break;
      }
    }

    // public function to close open menu
    close() {
      this.toggleExpand(this.openIndex, false);
    }

    onButtonClick(event) {
      event.preventDefault();

      let button = event.target;
      let buttonIndex = this.topLevelNodes.indexOf(button);
      let buttonExpanded = button.getAttribute(expandedAttr) === 'true';

      this.toggleExpand(buttonIndex, !buttonExpanded);
    }

    onButtonKeyDown(event) {
      let targetButtonIndex = this.topLevelNodes.indexOf(document.activeElement);

      // close on escape
      if (event.key === 'Escape') {
        this.toggleExpand(this.openIndex, false);
      }

      // move focus into the open menu if the current menu is open
      else if (
        this.useArrowKeys &&
                this.openIndex === targetButtonIndex &&
                event.key === 'ArrowDown'
      ) {
        event.preventDefault();
        this.menuID = this.topLevelNodes[this.openIndex].getAttribute('aria-controls');
        this.controlledNodes[this.menuID].querySelector('a').focus();
      }

      // handle arrow key navigation between top-level buttons, if set
      else if (this.useArrowKeys) {
        this.controlFocusByKey(event, this.topLevelNodes, targetButtonIndex);
      }
    }

    onLinkKeyDown(event) {
      let targetLinkIndex = this.topLevelNodes.indexOf(document.activeElement);

      // handle arrow key navigation between top-level buttons, if set
      if (this.useArrowKeys) {
        this.controlFocusByKey(event, this.topLevelNodes, targetLinkIndex);
      }
    }

    onMenuKeyDown(event) {
      if (this.openIndex === null) {
        return;
      }

      this.menuID = this.topLevelNodes[this.openIndex].getAttribute('aria-controls');
      let menuLinks = Array.prototype.slice.call(
        this.controlledNodes[this.menuID].querySelectorAll('a')
      );

      //fix if menu has tabs
      menuLinks = menuLinks.filter(function (item) {
        let style = window.getComputedStyle(item);

        return style.visibility !== 'hidden';
      });

      let currentIndex = menuLinks.indexOf(document.activeElement);

      // close on escape
      if (event.key === 'Escape') {
        this.topLevelNodes[this.openIndex].focus();
        this.toggleExpand(this.openIndex, false);
      }

      // handle arrow key navigation within menu links, if set
      else if (this.useArrowKeys) {
        this.controlFocusByKey(event, menuLinks, currentIndex);
      }
    }

    toggleExpand(index, expanded) {
      // close open menu, if applicable
      if (this.openIndex !== index) {
        this.toggleExpand(this.openIndex, false);
      }

      // handle menu at called index
      if (this.topLevelNodes[index]) {
        this.openIndex = expanded ? index : null;
        this.topLevelNodes[index].setAttribute(expandedAttr, expanded);
        this.menuID = this.topLevelNodes[index].getAttribute('aria-controls');
        this.toggleMenu(this.controlledNodes[this.menuID], expanded);

        if (this.controlledNodes[this.menuID] && this.controlledNodes[this.menuID].querySelector('.dropdown-item.featured')) {
          const targetFeaturedEl = this.controlledNodes[this.menuID].querySelector('.dropdown-item.featured');

          if (expanded && targetFeaturedEl) {
            const targetFeaturedAnchorEl = targetFeaturedEl.querySelector('a');
            const promo_position = this.topLevelNodes[index].getAttribute('href');
            const { textContent: link_text } = targetFeaturedAnchorEl;
            const link_url = targetFeaturedAnchorEl.getAttribute('href');
            const event = getFeaturedNavArticleEvent({ link_url, link_text, promo_position });

            this.topLevelNodes[index].dispatchEvent(event);
          }
        }
      }
    }

    toggleMenu(domNode, show) {
      if (!domNode) return;

      // domNode.style.display = show ? 'block' : 'none';

      let $dropdown = $(domNode);
      let $item = $dropdown.closest('li');
      let isDesktop = getIsDesktop();
      let duration = isDesktop ? durationDesktop : durationDefault;
      let isOpen = !show;
      $item[`${isOpen ? 'remove' : 'add'}Class`](openClass);

      if (!isOpen) {
        $dropdown.css('visibility', 'visible');
        $headerMenuBack.addClass('show').find('span').text($item.find('.menu-item-link a').text());
        if (!isDesktop) {
          $headerInner.css('overflow-y', 'hidden');
        }
      }

      $dropdown[`slide${isOpen ? 'Up' : 'Down'}`](duration, function () {
        if (isOpen) {
          $(this).css('visibility', 'hidden');

        } else if (!isDesktop) {
          requestAnimationFrame(function () {
            let itemPositionTop = $item.position().top;

            if (Math.sign(itemPositionTop) === -1) {
              $headerInner.animate({scrollTop: ($headerInner.scrollTop() + itemPositionTop - headerInnerIndentTop)}, 0);
            }
          });
        }
      });

      //$btn.attr(expandedAttr, !isOpen);
    }

    updateKeyControls(useArrowKeys) {
      this.useArrowKeys = useArrowKeys;
    }
  }

  /* Initialize Disclosure Menus */
  win.addEventListener(
    'load',
    function () {
      let _scrollEventToggle = scrollEventToggle();
      let menus = document.querySelectorAll(`.${navListClassName}, .header-links-list`);
      let disclosureMenus = [];
      let i = 0;
      for (; i < menus.length; i++) {
        disclosureMenus[i] = new DisclosureNav(menus[i]);
      }

      $body.on('click', function (e) {
        let $target = $(e.target);
        let isOpenMobileMenuBtn = $target.hasClass(openMenuName);
        let isOpenDropDown = false;
        i = 0;

        if (isOpenMobileMenuBtn) { //open mobile menu
          e.preventDefault();
          let attrValue = JSON.parse($target.attr(expandedAttr));

          $target.attr(expandedAttr, !attrValue);
          $body.toggleClass(showMenuClass);
          _scrollEventToggle[!attrValue ? 'disable' : 'enable'](true);
        }

        for (; i < disclosureMenus.length; i++) { //check is has open menu dropdowns
          if (disclosureMenus[i].openIndex !== null) {
            isOpenDropDown = true;
            break;
          }
        }

        if (isOpenDropDown) {
          i = 0;

          for (; i < disclosureMenus.length; i++) {
            let openIndex = disclosureMenus[i].openIndex;

            if (openIndex !== null) {
              let openItem = disclosureMenus[i].topItems[openIndex];

              if (!$target.closest(openItem).length) {
                let isMainMenu = disclosureMenus[i].rootNode.classList.contains(navListClassName);
                let isHideDropdown = !(isMainMenu && !getIsDesktop());

                if (isHideDropdown) {
                  disclosureMenus[i].close();
                }
              }
            }
          }
        }
      });
      fixInnerItemsPosition();
    },
    false
  );

  /* fixed position for header-bottom */


  $win.on('load resize scroll', function (e) {
    headerToggleClass(win.scrollY);

    if (e.type === 'resize') {
      fixInnerItemsPosition();
    }
    if (window.innerWidth >= 1200) {
      $('.dropdown-right').each(function () {
        $(this).find('.dropdown-right-inner.tab').removeClass('active');
        $(this).find('.dropdown-right-inner.tab:first-child').addClass('active');
      });

      $('.short-products-list').each(function () {
        $(this).find('>li').removeClass('active');
        $(this).find('>li:first-child').addClass('active');
      });
    } else {
      if (!$('.menu-item-has-children').hasClass('open') && !$body.hasClass('tab-menu-active')) {
        $headerMenuBack.removeClass('show');
        if (!getIsDesktop) {
          $headerInner.css('overflow-y', $headerInnerOverflow);
        }
      }
      $('.short-products-list li').removeClass('active');
    }
  });

  $win.on(orientationchangeEvent, throttle(fixInnerItemsPosition, getEventDelay(orientationchangeEvent)));

  const navMoreEls = document.querySelectorAll('.dropdown-item li .more') || [];

  navMoreEls.forEach(el => {
    const $btn = $(el);
    const $item = $(el.closest('li'));

    el.addEventListener('click', event => {
      event.stopPropagation();
      event.preventDefault();
      document.querySelector('.menu-item.open .dropdown-right-inner.active .dropdown-column a').focus();
    });

    el.addEventListener('focus', () => {
      shortProductsLogics($btn, $item);
    });
  });

  const featuredNavArticleLinkEls = document.querySelectorAll('.nav-dropdown .dropdown-column.has-featured a') || [];

  featuredNavArticleLinkEls.forEach(el => {
    el.addEventListener('click', event => {
      const { target } = event;
      const link_url = target.getAttribute('href');
      // const { textContent: link_text } = target;
      const promo_location = target.getAttribute('data-parent-link');
      const navigation_level = target.getAttribute('data-navigation-level');
      const navigation_type = navigation_level ? `nav_primary_${navigation_level}` : null;

      // promo status of interacted
      const dl_info = {
        event: 'internal_promo',
        navigation_type,
        promo_status: 'interacted',
        promo_location,
        promoted_page: link_url,
        promo_type: 'nav promo'
        // link_text
      };
      // console.log(dl_info);
      sendToDataLayer(dl_info);
    });
  });
}

function getZoom() {
  let zoom = Math.ceil(((window.outerWidth - 10)
    / window.innerWidth) * 100);
  
  const bodyEl = document.querySelector('body');
  
  if (zoom > 100) {
    bodyEl.classList.add('zoomed');
  } else if (bodyEl.classList.contains('zoomed')) {
    bodyEl.classList.remove('zoomed');
  }
}

window.addEventListener('resize', getZoom, false);
getZoom();
